.App {
	text-align: center;
	background-color: 'blue';
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
}

.buttonLabel {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.64;
	letter-spacing: normal;
	color: #374750;
}

.linkButton {
	background: none;
	border: none;
	padding: 0;
	color: #069;
	text-decoration: underline;
	cursor: pointer;
}

.linkButtonBootstrap {
	background: none !important;
	border: none !important;
	padding: 0 !important;
	color: #069 !important;
	text-decoration: underline !important;
	cursor: pointer !important;
}

.colTitle {
	text-align: right;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
